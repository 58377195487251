<template>

  <div class="partnerPage">
    <v-app id="vApp">


      Add Partner Page
   
    </v-app>
  </div>
</template>
<script>
  import '@/plugins/vuetify'
 
  import userModule from '@/store/user'
  import signinModule from '@/store/signin'
  import searchModule from '@/store/search'
  //import HeedarOverlay from '@/components/HeedarOverlay'

  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "partner",
    components: {
      //HeedarOverlay
    },
    data () {
      return {
        category: null,
        parentCategory: null,
        contextCategory: null,
        specificityLevel: null,


        overlayType: null,
        overlayCategory: null,
        overlayContextCategory: null,
        overlayParentCategory: null,
        overlaySpecificityLevel: null,
        overlayActionMeanCategory: null,


      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'role'
      ]),

      
    },
    watch: {
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('search', [
        'setShowSearch'
      ]),

      showOverlay () {
        //this.overlayCategory = payload.category
        //this.overlayType = payload.ctaType
        window.scrollTo(0,0)
        document.getElementById("heedarOverlay").style.display = "block";
      },
      overlayOff () {
        //this.overlayCategory = null
        //this.overlayType = null
        window.scrollTo(0,0)
        document.getElementById("heedarOverlay").style.display = "none";
      }


      
    },
    beforeCreate () {
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)

      
    },
    async created () {
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/heedar'});
      await this.autoSignIn()
      
    },

    mounted () {
      this.setShowSearch(false)
      
    },
    updated () {
      
    },
    
  }
</script>
<style scoped>

.partnerPage::-webkit-scrollbar {
  display: none;
}
.partnerPage {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
#vApp {
 background-color: transparent;
}




@media screen and (min-width: 360px) {


}


</style>