import db from '@/db'
import firebase from '@/firebase'
//import router from '@/router'


import {
  NEW_VISITOR,

} from '@/store/states'

import {
  //ADD_USER,
  //UPDATE_USER,
  //REMOVE_USER,
  //PUSH_USER,
} from './mutations'


const state = {
  //Unable to keep user auth state here because this module includes the large firebase libraries within '@/db'
  //User auth profile is kept in 'auth' module.  That module does not include any heavy libraries, so it can be
  //included within site entry point without adding a hit to performance

}

const getters = {
  
}

const mutations = {

}

const actions = {

  

  async createNewUser (_, user) {
    return new Promise((resolve) => {

      (async () => {

        var ref = await db.collection('users').doc(user.uid)
        var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone  //may need to add exception handling

        //create zone number, random number between 1-100
        var zoneNumber = Math.floor(Math.random() * 100) + 1;


        //var moment = require('moment-timezone');
        //var nowTime = moment();
        //console.log('nowTime: '+nowTime);
        //var userTimezone = moment.tz.guess();
        var today = new Date();
        await ref.set({
          'allowPublicComments': true,
          'allowPublicCommentsLastChangedDate': null,
          'commentsLastViewedDate': null,
          'dateCreated': today,
          'dateLastLogin': today,
          'displayName': user.displayName,
          'email': user.email,
          'experiencePhase': NEW_VISITOR,
          'hasInitializedConsole': false,
          'nickname': null,
          'nicknameLastChangedDate': null,
          'notificationSettings': {
            'allowNotifications': true,
            'allowNotificationsLastChangedDate': null,
            //'lastDiscoveryNotifTimestamp': null,
            //'lastGroupNotifTimestamp': null,
            //'notificationDailyLimit': 3,
            //'notificationDeliveryReoccurancePeriod': 'every_other_day',
            //'notificationDeliveryPeriodJournal': "evening",
            //'notificationDeliveryPeriodDiscovery': "afternoon",
            //'notificationDeliveryPeriodGroup': "evening",
            //'notificationToggle': false,
            //'notificationToggleJournal': true,
            //'notificationToggleAlerts': false,
            //'notificationToggleAlertsHeedar': true,
            //'notificationToggleDiscovery': false,
            //'notificationToggleGroup': false,
            //'notificationToggleActivity': false
          },
          //'journalingState': {
          //  'dateNextTaskScheduled': null,
          //  'dateLastTaskCompleted': null
          //},
          'role': "user",
          'settings': {
            'cookingSettings': {
              'currentMenu': 'Menu 1',
              'cookingDays': ['Tuesday', 'Thursday', 'Friday'],
              'currentCookingRoutineId': null,
              'currentCookingRoutineTemplateId': null,
            },
          },
          'userMinderIds': [],
          'userLang': 'en',
          'userTimezone': userTimezone,
          'userUid': user.uid,
          'userAcceptedTerms': false,
          'zoneNumber': zoneNumber
        })

      })()

      resolve()
    })

    //Need to set user state



  },
  async checkIfUserExists (_, uid) {
    return new Promise((resolve) => {
      (async () => {
        const ref = await db.collection('users').doc(uid);
        return ref.get().then((doc) => {
          //console.log("doc.exists: "+ doc.exists)
          if(doc.exists) {
            resolve(true);
          }else {
            resolve(false);
          }
        })
      })()

    })
  },

  

  async getUserState ({dispatch}, uid) {
    return new Promise((resolve, reject) => {
      (async () => {
    

        try {
          // snap = await db.collection('users').doc(uid).get()  //problem is here; don't have permissions

          await db.collection('users').doc(uid).onSnapshot(async(snap) => {

            if(snap.exists) {
              //console.log("snap.data()",snap.data())
              await dispatch('settings/setUserSettings', {data: snap.data()}, {root: true})
              await dispatch('auth/setRole', snap.data().role, { root: true })
              await dispatch('auth/setTermsOfUse', snap.data().userAcceptedTerms, { root: true })
              await dispatch('auth/setInitializedConsole', snap.data().hasInitializedConsole, {root: true})
              await dispatch('auth/setCommentsLastViewedDate', snap.data().commentsLastViewedDate, { root: true })
              //var bool
              //bool = (snap.data().userMinderIds.length > 0) ? true : false
              //await dispatch('auth/setHasMinders', bool, { root: true})
              //await dispatch('experience/setXpPhase', {phase: snap.data().experiencePhase, 'vuexOnly': true}, { root: true })
              resolve()
            }else {
              //console.log("no snapshot received in user/getUserState")
              reject()
            }

          })


        } catch(e) {
          /* eslint-disable no-console */
          console.log("error was here: "+e.message)
        }
      
      })()
    })
  },
  async logSignInTime (_, uid) {
    return new Promise((resolve) => {
      (async () => {
        await db.collection('users').doc(uid).set({
          'dateLastLogin': new Date()
        }, {merge: true})
        resolve()
      })()
    })
  },

  async upgradeUser (_, upgradeObj) {
    return new Promise((resolve) => {
      (async () => {
        await db.collection('users').doc(upgradeObj.uid).set({
          'email': upgradeObj.email,
          'displayName': upgradeObj.displayName,
          'photoURL': upgradeObj.photoURL,
        }, {merge: true})
        resolve()
      })()
    })
  },
  
  setupUser ({dispatch}, payload) {
    return new Promise((resolve) => {
      //commit(SET_PROFILE, payload.user)
      dispatch('getUserState', {uid: payload.user.uid})
      resolve()
    })
  },
  async mergeUserAcct (_, payload) {
    return new Promise((resolve) => {
      (async () => {
        const originalUid = payload.originalUid
        const returnedUid = payload.returnedUid
        await db.collection('users').doc(originalUid).set({
          mergedAccountUid: returnedUid,
          mergedAccountEmail: returnedUid
        }, {merge: true})
        resolve()
      })
    })
  },
  async setUserLangInFB (_, payload) {
    return new Promise((resolve) => {
      (async () => {
        await db.collection('users').doc(payload.userUid).set({
          'userLang': payload.userLang
        }, {merge: true})
        resolve()
      })()
    })
  },
  async updateNotificationHistory (_, payload) {
      var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{
        unsubscribe()
        if(user) {
          await db.collection('users').doc(payload.userUid).collection('notificationHistory').doc(payload.notifId).set({
            'dateResponseReceived': new Date()
          }, {merge:true})
          return
        }else{
          return
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}