//import db from '@/db'
import firebase from '@/firebase'
import router from '@/router'
import store from '@/store'

import {
  ADD_FOO

} from './mutations'

const state = {
  foo: 'bar'
}

const getters = {
  foo: ({foo}) => foo
}

const mutations = {

  [ADD_FOO] (state, data) {
    state.foo = data
  },
}




const actions = {

  
  async upgradeAccount ({dispatch}, payload) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async (originalUser) => { 

        /*eslint-disable no-console */
        
        var credential = firebase.auth.GoogleAuthProvider.credential(
          payload.googleUser.getAuthResponse().id_token);

        firebase.auth().currentUser.linkWithCredential(credential)
        .then(async (usercred) => {
          var newUser = usercred.user;
          console.log("Original user", originalUser)
          console.log("Anonymous account successfully upgraded", newUser);

          const profile = payload.googleUser.getBasicProfile()
          var obj = {
            displayName: profile.getName(),
            photoURL: profile.getImageUrl(),
            email: profile.getEmail(),
            uid: payload.uid,
            isAnonymous: false,
          }
          
          await dispatch('auth/setProfile', obj, {root: true})
          await dispatch('user/upgradeUser', obj, {root: true})


          resolve()
        }).catch((error) => {
          console.log("Error upgrading anonymous account", error);
          reject()
        });

        
      })
    })
  }, 
  


  async googleLogin ({dispatch}, redirectPath) {
    //console.log("signin/googleLogin() called")
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithPopup(provider).then(async(result) => {

        /* eslint-disable no-console */
        //console.log("result.user", result.user)
        //console.log("provider data", result.user.providerData[0].displayName)


        await dispatch('auth/setProfile', result.user, { root: true }).then(async() => {
          if(result.additionalUserInfo.isNewUser){
            await dispatch('user/createNewUser', result.user, {root: true})
            await dispatch('user/logSignInTime', result.user.uid, {root: true})           //I removed the 'await' from beginning of this line, if things screw up, add it back in
            await dispatch('user/getUserState', result.user.uid, {root: true})
            // dispatch('notification/sendWelcomeNotification', result.user, {root: true})
          }else{
            await dispatch('user/logSignInTime', result.user.uid, {root: true})           //I removed the 'await' from beginning of this line, if things screw up, add it back in
            await dispatch('user/getUserState', result.user.uid, {root: true})
          }
          

          

          resolve(redirectPath)
        }).catch(error => {
          /* eslint-disable no-console */
          console.log(error)
          reject()
        })
      })
    })
    
  },

  async anonymousLogin ({dispatch}) {
    return new Promise((resolve) => {
      firebase.auth().signInAnonymously().catch((error) => {   
        /* eslint-disable no-console */
        console.log("anonymous login errors: "+error.message)
      });
      firebase.auth().onAuthStateChanged(async (user) => {  
        if(user) {
          var bool = await dispatch('user/checkIfUserExists', user.uid, {root: true})
          if(bool === false) {
            await dispatch('user/createNewUser', user, {root: true})
            await dispatch('user/getUserState', user.uid, {root: true})
            await dispatch('auth/setAnonymousProfile', user, {root: true})
            await dispatch('user/logSignInTime', user.uid, {root: true})
          }else {
            await dispatch('user/getUserState', user.uid, {root: true})
            await dispatch('auth/setAnonymousProfile', user, {root: true})
            await dispatch('user/logSignInTime', user.uid, {root: true})
          }

          //configure Google Analytics with user uid
          //gtag('config', 'G-H9RD8W4MLR', {
          //  'user_id': user.uid
          //})

          resolve(user)
        }
      })
    })
  },
          



  emailLogin ({commit}, payload) {
    commit('setLoading', true)
    firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(firebaseUser => {
        commit('setUser', {email: firebaseUser.user.email})
        commit('setLoading', false)
        commit('setError', null)
        router.push('/dashboard')
      })
      .catch(error => {
        commit('setError', error.message)
        commit('setLoading', false)
      })
  },
  async autoSignIn ({dispatch}) {
    //console.log("signin/autoSignIn() was called")
    //commit(SET_PROFILE, payload)
    //dispatch('user/getUserState', {uid: payload.uid}, {root: true})
    var autoSignInFlag = store.state.auth.autoSignInOn;
    //console.log("autoSignInFlag is: "+autoSignInFlag)
    if(!autoSignInFlag) {
      var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{
        if(user) {
          var acctType = store.state.auth.profile.accountType
          var loggedInBool = store.state.auth.loggedIn
          if(loggedInBool === true) {
            //heediUser
            if(acctType == 'heediUser') {
              await dispatch('auth/setProfile', user, {root: true})
            }
            //anonymous
            if(acctType == 'anonymous') {
              await dispatch('auth/setAnonymousProfile', user, {root: true})
            }
            await dispatch('user/getUserState', user.uid, {root: true})
          }

          //configure Google Analytics with user uid
          //gtag('config', 'G-H9RD8W4MLR', {
          //  'user_id': user.uid
          //})


          //set autoSignInOn in auth to true
          await dispatch('auth/setAutoSignInFlag', true, {root: true})

        } else {
          /* eslint-disable no-console */
          console.log("no user to auto signin")
        }
      unsubscribe()
      })
    }
    return
  },
  logout ({dispatch}) {
    firebase.auth().signOut().catch((error) => {  
      /* eslint-disable no-console */
      console.log("anonymous login errors: "+error.message)
    });
    //console.log("logout called")
    dispatch('auth/removeProfile', {data: null}, {root: true})
    //router.push('/')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}