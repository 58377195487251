import Vue from 'vue'
import Vuetify, {
  VApp,
  VToolbar,
  VList,
  VBtn,
  VSlider,
  VSelect,
  VStepper,
  VSwitch,
  VSparkline, 
  VSheet,
  VCard,
  VCombobox,
  VMenu
} from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  components: {
    VApp,
    VToolbar,
    VList,
    VBtn,
    VSlider,
    VSelect,
    VStepper,
    VSwitch,
    VSparkline,
    VSheet,
    VCard,
    VCombobox,
    VMenu

  },
  iconfont: 'md',
})
