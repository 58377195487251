import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'




if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBITbZ3jExQCxDlWGnllF8Y0_7681PNzVQ',
    authDomain: 'heedi-1141.firebaseapp.com',
    databaseURL: 'https://heedi-1141.firebaseio.com',
    projectId: 'heedi-1141',
    storageBucket: 'heedi-1141.appspot.com',
    //messagingSenderId: '733999034905'
  })

  
}

export default firebase
