//Various application states for heedi objects



//User experiencePhase
export const NEW_VISITOR = "NEW_VISITOR"
export const FOCUS_FLOW_COMPLETED = "FOCUS_FLOW_COMPLETED"
export const ACTION_FLOW_COMPLETED = "ACTION_FLOW_COMPLETED"
export const ROUTINE_FLOW_COMPLETED = "ROUTINE_FLOW_COMPLETED"
export const ROUTINE_ADD_FLOW_COMPLETED = "ROUTINE_ADD_FLOW_COMPLETED"
export const JOURNAL_FLOW_COMPLETED = "JOURNAL_FLOW_COMPLETED"
export const CESSATION_FLOW_COMPLETED = "CESSATION_FLOW_COMPLETED"
export const TALLY_FLOW_COMPLETED = "TALLY_FLOW_COMPLETED"
export const START_FLOW_COMPLETED = "START_FLOW_COMPLETED"
